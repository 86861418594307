<mat-icon
        *ngIf="!(vendor?.iconImageSource)" class="icon-fallback"
>
    business
</mat-icon>
<img
        *ngIf="!!(vendor?.iconImageSource)" class="icon"
        [src]="'assets/vendor/' + vendor.iconImageSource"
        [alt]="vendor.displayName ?? '' + ' logo icon'"
/>
