import { Component, Input } from '@angular/core';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';
import { ProjectError } from '../../../model/error-enums';
import { IProjectInfo } from '../../../model/i-project-info';

@Component({
  selector: 'app-batch-request-complete',
  templateUrl: './batch-request-complete.component.html',
  styleUrls: ['./batch-request-complete.component.scss'],
})
export class BatchRequestCompleteComponent {

  @Input()
  vendorServiceSelection: IVendorServiceSelection;

  @Input()
  requestStatus: 'pending' | 'failed' | 'success' = 'pending';

  @Input()
  errorMessage?: ProjectError;

  @Input()
  failedProjects: IProjectInfo[] = [];

  @Input()
  successfulProjects = 0;

  displayedColumns = ['claimNum', 'street', 'city', 'state', 'zip', 'country'];

  constructor() { }

  getErrorTranslateKey(): string {
    switch (this.errorMessage) {
      case ProjectError.AlreadySubmitted:
        return 'PROJECT-ERROR.ALREADY-SUBMITTED';
      case ProjectError.NoAvailability:
        return 'PROJECT-ERROR.AVAILABILITY';
      default:
        return 'PROJECT-ERROR.GENERIC';
    }
  }
}
