import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IHealthCheckResponse } from '../model/i-health-check-response';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  tpiWebApiUrl: string;
  constructor(private httpClient: HttpClient) {
    this.tpiWebApiUrl = environment.apiUrls.tpiWebApi;
  }

  health(): Observable<IHealthCheckResponse> {
    return this.httpClient.get<IHealthCheckResponse>(`${this.tpiWebApiUrl}/public/health`);
  }
}
