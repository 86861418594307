import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intlDate'
})
export class IntlDatePipe implements PipeTransform {
  static readonly dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });

  transform(value: string | number | Date): unknown {
    return IntlDatePipe.dateTimeFormatter.format(typeof value === 'string' ? new Date(value + (value.endsWith('Z') ? '' : 'Z')) : value);
  }
}
