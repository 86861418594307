export enum ProjectStatus {
  New = 'New',
  Unprocessed = 'Unprocessed',
  NotifyOfCreation = 'NotifyOfCreation',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  SendToXA = 'SendToXA',
  Complete = 'Complete',
  Failure = 'Failure',
  NotifiedProviderWithError = 'NotifiedProviderWithError',
}
