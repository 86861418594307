<mat-card appearance="outlined">
    <mat-card-header>
        <app-vendor-full-logo class="logo" [vendor]="vendorServiceSelection?.vendor" [alignment]="'center'"
        ></app-vendor-full-logo>
        <ng-container *ngIf="vendorServiceSelection?.service?.serviceDisplayName; else defaultServiceDisplayName">
           <div>{{ vendorServiceSelection.service.serviceDisplayName }}</div>
        </ng-container>
        <ng-template #defaultServiceDisplayName>
            <div>{{ 'VENDOR-SERVICE.' + vendorServiceSelection?.service.type | translate }}</div>
        </ng-template>
    </mat-card-header>
    <h1>
        <div class="request-status" *ngIf="requestStatus === 'pending'">
            <mat-spinner [diameter]="48" class="status-icon"></mat-spinner>
            <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.PENDING' | translate }}</span>
        </div>
        <div class="request-status" *ngIf="requestStatus === 'failed'">
            <mat-icon color="warn" class="status-icon">error_outline</mat-icon>
            <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.FAILED' | translate }}</span>
        </div>
        <div class="request-status" *ngIf="requestStatus === 'success'">
            <mat-icon color="primary" class="status-icon">check_circle_outline</mat-icon>
            <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.SUCCESS' | translate }}</span>
        </div>
    </h1>

    <div *ngIf="requestStatus === 'failed'">
        <h2 class="error-detail">{{ getErrorTranslateKey() | translate:{vendorName: vendorServiceSelection.vendor.displayName} }}</h2>
    </div>

    <mat-card-actions *ngIf="shouldShowResetButton()">
    <div class="button-container">
        <button mat-raised-button
                (click)="onResetButtonClick()"
                [disabled]="showButtonSpinner"
        >{{ getResetButtonTranslateKey() | translate }}</button>
        <mat-spinner class='spinner' *ngIf="showButtonSpinner" [diameter]="24"></mat-spinner>
    </div>
    </mat-card-actions>
</mat-card>
