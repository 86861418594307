import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from '../services/project.service';
import { RouteEnum } from '../enums/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfoGuard  {

  constructor(private router: Router, private projectService: ProjectService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!!this.projectService.projectGuid || !!this.projectService.batchGuid) {
      return true;
    } else if (!!sessionStorage.getItem(this.projectService.projectSessionKey)
      || !!sessionStorage.getItem(this.projectService.batchSessionKey)) {
      this.projectService.setProjectOrBatchGuidFromSessionStorage();
      return true;
    }

    this.router.navigate([RouteEnum.PROJECT_ERROR]);
    return false;
  }
}
