import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ProjectService } from 'src/app/shared/services/project.service';
import { VendorService } from 'src/app/shared/services/vendor.service';
import { IBatchFormResult } from 'src/app/model/i-batch-form-result';
import { IVendorInfo } from 'src/app/model/i-vendor-info';
import { IVendorServiceSelection } from 'src/app/model/i-vendor-service-selection';
import { BatchProjectInfo } from 'src/app/model/batch-project-info';
import { ProjectError } from 'src/app/model/error-enums';
import { IProjectInfo } from 'src/app/model/i-project-info';
import { ProjectStatus } from 'src/app/model/project-status';
import { environment } from '../../../../environments/environment';
import { RequestCompleteComponent } from '../../project-service-request/request-complete/request-complete.component';

@Component({
  selector: 'app-batch-stepper-pages-container',
  templateUrl: './batch-stepper-pages-container.component.html',
  styleUrls: ['./batch-stepper-pages-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BatchStepperPagesContainerComponent implements OnInit {

  windowWidth: number;
  orientationBreakpoint = 960; // greater than sm

  @ViewChild(MatStepper)
  stepper: MatStepper;

  vendors: IVendorInfo[] = [];
  vendorServiceSelection: IVendorServiceSelection | null = null;

  @Input()
  batchProjectInfo: BatchProjectInfo | null = null;

  requestStatus: RequestCompleteComponent['requestStatus'] = 'pending';
  requestErrorMessage?: ProjectError;

  @Output()
  requestComplete = new EventEmitter<void>();

  @Output()
  makeAnotherRequest = new EventEmitter<void>();

  failedProjects: IProjectInfo[] = [];
  successfulProjects = 0;

  constructor(
    private projectService: ProjectService,
    private vendorService: VendorService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    this.vendorService.vendorList$.subscribe(vendors => {
      this.vendors = vendors.sort((a, b) => a.displayName.localeCompare(b.displayName));
    });

    if (!environment.production) {
      console.log('dev env: setting test functions on window object');
      // @ts-ignore
      window.testSetRequestPending = () => {
        this.requestStatus = 'pending';
        this.requestErrorMessage = undefined;
        this.cdr.detectChanges();
      };
      // @ts-ignore
      window.testSetRequestFailed = (error: any) => {
        this.requestStatus = 'failed';
        this.requestErrorMessage = error;
        this.cdr.detectChanges();
      };
      // @ts-ignore
      window.testSetRequestSuccess = () => {
        this.requestStatus = 'success';
        this.requestErrorMessage = undefined;
        this.cdr.detectChanges();
      };
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.windowWidth = window.innerWidth;
  }

  onStepSelectionChange($event: StepperSelectionEvent): void {
    if ($event.selectedIndex === 0) {
      this.stepper.steps.get(0).completed = false;
      this.vendorServiceSelection = null;
    }
  }

  onVendorSelected(selection: IVendorServiceSelection): void {
    this.vendorServiceSelection = selection;
    this.stepper.steps.get(0).completed = true;
    this.stepper.selectedIndex = 1;
  }

  onConfirmation($event: IBatchFormResult): void {
    this.batchProjectInfo?.updateInfoWithFormResult($event);
    this.batchProjectInfo.updateVendorServiceSelection(this.vendorServiceSelection);
    this.stepper.steps.get(1).completed = true;
    this.stepper.selectedIndex = 2;
    this.submitServiceRequest();
  }

  onConfirmCancel(): void {
    this.stepper.selectedIndex = 0;
    this.stepper.steps.get(0).completed = false;
    this.vendorServiceSelection = null;
  }

  submitServiceRequest(): void {

    if (this.batchProjectInfo.projects.some(p => p.status !== ProjectStatus.New)) {
      this.requestStatus = 'failed';
      this.requestErrorMessage = ProjectError.AlreadySubmitted;
      console.warn('refusing to do anything with not "New" project');
      this.failedProjects = this.batchProjectInfo.projects;
      return;
    }

    this.projectService.checkAvailabilityForBatch(this.vendorServiceSelection, this.batchProjectInfo).subscribe(availabilityLists => {

      this.failedProjects = this.failedProjects.concat(availabilityLists.unavailableProjects);
      if (!environment.production) {
        console.log(availabilityLists);
      }

      if (availabilityLists.availableProjects.length > 0) {
        this.projectService.submitBatchRequest(new BatchProjectInfo(availabilityLists.availableProjects), this.vendorServiceSelection.service.serviceId).subscribe(res => {
          this.requestStatus = 'success';
          this.stepper.steps.get(2).completed = this.requestStatus === 'success';
          this.batchProjectInfo.projects.forEach((project) => project.status = ProjectStatus.NotifyOfCreation);
          this.failedProjects = this.failedProjects.concat(res.body);
          this.successfulProjects = this.batchProjectInfo.projects.length - this.failedProjects.length;
          this.requestComplete.emit();
        }, (errorResponse: HttpErrorResponse) => {
          console.error(errorResponse);
          this.requestStatus = 'failed';
          this.requestErrorMessage = errorResponse?.error?.message ?? 'error';
          this.failedProjects = this.failedProjects.concat(availabilityLists.availableProjects);
        });
      } else {
        this.requestStatus = 'failed';
        this.requestErrorMessage = ProjectError.NoAvailability;
      }
    });
  }

  onReset(): void {
    this.stepper.reset();
    this.stepper.selectedIndex = 0;
    this.requestStatus = 'pending';
    this.requestErrorMessage = undefined;
  }
}
