import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProjectInfo } from '../../../model/i-project-info';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';
import { StateOrProvince } from '../../../shared/enums/locations.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IServiceInfo, IVendorInfo } from '../../../model/i-vendor-info';
import { IBatchFormResult } from '../../../model/i-batch-form-result';
import { BatchProjectInfo } from '../../../model/batch-project-info';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-batch-confirm-information',
  templateUrl: './batch-confirm-information.component.html',
  styleUrls: ['./batch-confirm-information.component.scss']
})
export class BatchConfirmInformationComponent implements OnInit {

  @Input()
  initialProjectInformation: BatchProjectInfo;

  @Input()
  country = 'US';

  @Input()
  vendorServiceSelection: IVendorServiceSelection;

  @Output()
  confirmation = new EventEmitter<IBatchFormResult>();

  @Output()
  cancel = new EventEmitter<void>();

  form: FormGroup;
  displayContactInformation = false;
  displayIsPrimaryCheckboxes = false;
  displayPhoneEmailFields = false;
  stateOrProvince = StateOrProvince;
  defaultBatchFieldValue = 'Mult';

  propertyOwnerCheckboxIsChecked = false;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.setContactInformationVisibility(this.initialProjectInformation.projects[0], this.vendorServiceSelection.service);
    this.setForm(this.initialProjectInformation.projects[0]);
    this.loadProjectInfo(this.initialProjectInformation.projects[0]);
  }

  private setContactInformationVisibility(projectInformation: IProjectInfo, service: IServiceInfo): void {
    this.displayContactInformation = service.includePolicyHolderData;
    this.displayIsPrimaryCheckboxes = service.includePolicyHolderData;
    this.displayPhoneEmailFields = true;

    if (!!service.includeClaimantProfiles && !service.includePolicyHolderData) {
      const profiles = service.includeClaimantProfiles.split('|');
      if (profiles.some(p => p === projectInformation.profile)) {
        this.displayContactInformation = true;
        this.displayIsPrimaryCheckboxes = false;
        this.displayPhoneEmailFields = false;
      }
    }
  }

  private setForm(projectInfo: IProjectInfo): void {
    this.form = this.fb.group({
      claimRepContact: this.fb.group({
        primaryContact: [Boolean(!projectInfo?.propertyOwnerContactInfo?.primaryContact)],
        name: ['', Validators.required],
        phone: ['', Validators.required],
        extension: [''],
        email: ['', [Validators.required, Validators.email]]
      }),
      note: [''],
    });

    if (this.displayContactInformation) {
      this.propertyOwnerCheckboxIsChecked = !!projectInfo?.propertyOwnerContactInfo?.primaryContact;
    }

    this.form.markAllAsTouched();
  }

  private loadProjectInfo(projectInfo: IProjectInfo): void {
    if (!this.form) {
      return;
    }

    this.form.patchValue({
      claimRepContact: {
        name: projectInfo.claimRepContactInfo?.name,
        phone: projectInfo.claimRepContactInfo?.phone,
        extension: projectInfo.claimRepContactInfo?.extension,
        email: projectInfo.claimRepContactInfo?.email,
      },
      note: projectInfo.note,
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const result: IBatchFormResult = {
        claimRepContactInfo: this.form.get('claimRepContact').value,
        note: this.form.get('note').value,
      };
      this.confirmation.emit(result);
    }
  }

  togglePrimaryOwner(event: MatCheckboxChange): void {
    this.propertyOwnerCheckboxIsChecked = !this.propertyOwnerCheckboxIsChecked;
    this.form.get('claimRepContact.primaryContact').setValue(!this.propertyOwnerCheckboxIsChecked);
  }
}
