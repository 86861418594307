<div class="logo-fallback" *ngIf="!(vendor?.logoImageSource)">
    <mat-icon>business</mat-icon>
    <span>{{ vendor?.displayName ?? '' }}</span>
</div>
<img
    #logoImg
    class="logo"
    *ngIf="!!(vendor?.logoImageSource)"
    [src]="'assets/vendor/' + vendor.logoImageSource"
    [alt]="vendor.displayName + ' logo'"
/>
