<mat-card appearance="outlined">
    <mat-card-header>
        <app-vendor-full-logo class="logo" [vendor]="vendorServiceSelection?.vendor" [alignment]="'center'"></app-vendor-full-logo>
        <div>{{ 'VENDOR-SERVICE.' + vendorServiceSelection?.service.type | translate }}</div>
    </mat-card-header>
    <h1>
        <div class="request-status" *ngIf="requestStatus === 'pending'">
            <mat-spinner [diameter]="48" class="status-icon"></mat-spinner>
            <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.PENDING' | translate }}</span>
        </div>
        <div class="request-status" *ngIf="requestStatus === 'failed'">
            <mat-icon color="warn" class="status-icon">error_outline</mat-icon>
            <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.FAILED' | translate }}</span>
        </div>
        <div *ngIf="requestStatus === 'success'">
            <div class="request-status">
                <mat-icon color="primary" class="status-icon">check_circle_outline</mat-icon>
                <span class="status-label">{{ 'SERVICE-REQUEST-STATUS.SUCCESS' | translate }}</span>
            </div>
            <span class="successful-projects">{{ 'SERVICE-REQUEST.SUCCESSFUL_PROJECTS' | translate }}: {{ this.successfulProjects }}</span>
        </div>
    </h1>

    <div *ngIf="requestStatus === 'failed'">
        <h2 class="error-detail">{{ getErrorTranslateKey() | translate:{vendorName: vendorServiceSelection.vendor.displayName} }}</h2>
    </div>

    <h1 *ngIf="failedProjects && requestStatus !== 'pending'">
        <div class="table-header">{{ 'SERVICE-REQUEST.FAILED_PROJECTS' | translate }}: {{ this.failedProjects.length }}</div>
    </h1>

    <table mat-table [dataSource]="failedProjects">
        <ng-container matColumnDef="claimNum">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.CLAIM_NUMBER' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.claimNum }}</td>
        </ng-container>
        <ng-container matColumnDef="street">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.STREET' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.street }}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.CITY' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.city }}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.STATE' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.state }}</td>
        </ng-container>
        <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.ZIP' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.zip }}</td>
        </ng-container>
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-INFO.COUNTRY' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.country }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</mat-card>
