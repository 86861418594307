<div class="container">
    <h2>State of TPI Web</h2>

    <div class="buttons">
        <button id="refresh-btn" mat-raised-button color="primary" (click)="runHealthCheck()">Resend</button>
        <button mat-flat-button color="primary">
            <a id="json-link" href="{{ getApiUrl() }}/public/health" target="_blank">View JSON</a>
            <mat-icon>open_in_new</mat-icon>
        </button>
    </div>

    <mat-spinner *ngIf="loading" id="health-loading-spinner" [diameter]="100"></mat-spinner>

    <mat-chip-option
            selected selectable=false
            color="warn" disableRipple
            class="request-failed"
            *ngIf="!loading && error"
    >
        <mat-icon>error</mat-icon>
        <span>Failed to communicate with TPI Web API</span>
    </mat-chip-option>

    <ng-container *ngIf="!loading && !error">
        <div class="collections-container" *ngFor="let collection of healthCheckData.collections">
            <table class="collections-table">
                <thead>
                    <tr>
                        <th class="collections-header" colspan="3">{{ collection.name }}</th>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <th>Healthy?</th>
                        <th>Duration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let check of collection.checks" class=""
                        [ngClass]="{'check-failed': !check.passed, 'check-passed': check.passed}"
                    >
                        <td>{{ check.name }}</td>
                        <td class="healthy-icon">
                            <mat-icon [color]="(check.passed ? 'primary' : 'warn')">{{ check.passed ? 'check_circle' : 'cancel' }}</mat-icon>
                        </td>
                        <td>{{ check.elapsed_seconds }} seconds</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>
