import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ProjectService } from '../../../shared/services/project.service';
import { VendorService } from '../../../shared/services/vendor.service';
import { IVendorInfo } from '../../../model/i-vendor-info';
import { IProjectInfo } from '../../../model/i-project-info';
import { RequestCompleteComponent } from '../request-complete/request-complete.component';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectStatus } from '../../../model/project-status';
import { ProjectError } from '../../../model/error-enums';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-stepper-pages-container',
  templateUrl: './stepper-pages-container.component.html',
  styleUrls: ['./stepper-pages-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperPagesContainerComponent implements OnInit {

  windowWidth: number;
  orientationBreakpoint = 960; // greater than sm

  @ViewChild(MatStepper)
  stepper: MatStepper;

  vendors: IVendorInfo[] = [];
  vendorServiceSelection: IVendorServiceSelection | null = null;

  @Input()
  projectInfo: IProjectInfo | null = null;

  requestStatus: RequestCompleteComponent['requestStatus'] = 'pending';
  requestErrorMessage?: ProjectError;

  @Output()
  requestComplete = new EventEmitter<void>();

  @Output()
  makeAnotherRequest = new EventEmitter<void>();

  constructor(
    private projectService: ProjectService,
    private vendorService: VendorService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    this.vendorService.vendorList$.subscribe(vendors => {
      this.vendors = vendors.sort((a, b) => a.displayName.localeCompare(b.displayName));
    });

    if (!environment.production) {
      console.log('dev env: setting test functions on window object');
      // @ts-ignore
      window.testSetRequestPending = () => {
        this.requestStatus = 'pending';
        this.requestErrorMessage = undefined;
        this.cdr.detectChanges();
      };
      // @ts-ignore
      window.testSetRequestFailed = (error: any) => {
        this.requestStatus = 'failed';
        this.requestErrorMessage = error;
        this.cdr.detectChanges();
      };
      // @ts-ignore
      window.testSetRequestSuccess = () => {
        this.requestStatus = 'success';
        this.requestErrorMessage = undefined;
        this.cdr.detectChanges();
      };
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.windowWidth = window.innerWidth;
  }

  onStepSelectionChange($event: StepperSelectionEvent): void {
    if ($event.selectedIndex === 0) {
      this.stepper.steps.get(0).completed = false;
    }
  }

  onVendorSelected(selection: IVendorServiceSelection): void {
    this.vendorServiceSelection = selection;
    this.stepper.steps.get(0).completed = true;
    this.stepper.selectedIndex = 1;
  }

  onConfirmation($event: IProjectInfo): void {
    this.projectInfo = $event;
    this.stepper.steps.get(1).completed = true;
    this.stepper.selectedIndex = 2;
    this.submitServiceRequest();
  }

  onConfirmCancel(): void {
    this.stepper.selectedIndex = 0;
    this.stepper.steps.get(0).completed = false;
    this.vendorServiceSelection = null;
  }

  submitServiceRequest(): void {
    if (this.projectInfo.status !== ProjectStatus.New) {
      this.requestStatus = 'failed';
      this.requestErrorMessage = ProjectError.AlreadySubmitted;
      console.warn('refusing to do anything with not "New" project');
      return;
    }

    this.projectService.submitServiceRequest(this.vendorServiceSelection, this.projectInfo).subscribe(res => {
        this.requestStatus = (res.ok) ? 'success' : 'failed';
        this.stepper.steps.get(2).completed = this.requestStatus === 'success';
        this.projectInfo.status = ProjectStatus.NotifyOfCreation;
        this.requestComplete.emit();
      },
      (errorResponse: HttpErrorResponse) => {
        console.error(errorResponse.error);
        this.requestStatus = 'failed';
        this.requestErrorMessage = errorResponse.error.message ?? 'error';
      }
    );
  }

  onReset(): void {
    this.stepper.reset();
    this.stepper.selectedIndex = 0;
    this.requestStatus = 'pending';
    this.requestErrorMessage = undefined;
  }

  onMakeAnotherRequest(): void {
    this.makeAnotherRequest.emit();
  }
}
