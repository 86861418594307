import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IVendorInfo } from '../../model/i-vendor-info';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  private readonly tpiWebApiUrl: string;

  protected vendorListSubject = new BehaviorSubject<IVendorInfo[]>([]);
  public vendorList$ = this.vendorListSubject.asObservable();

  constructor(protected http: HttpClient) {
    this.tpiWebApiUrl = environment.apiUrls.tpiWebApi;
  }

  public requestVendorList(serviceIds: (`${number}` | number)[]): Observable<IVendorInfo[]> {
    const url = new URL(`${this.tpiWebApiUrl}/vendor_info/`);

    for (const id of serviceIds) {
      url.searchParams.append('service_ids', id.toString());
    }

    return this.http.get<IVendorInfo[]>(url.toString()).pipe(tap(list => this.vendorListSubject.next(list)));
  }
}
