export enum ProjectError {
  ProjectIdRequired = 'project_id is required',
  NotFound = 'project not found',
  SubmitError = 'error submitting project',
  UpdateError = 'error updating project',
  AlreadySubmitted = 'project already submitted',
  NoAvailability = 'no availability',
  InternalServerError = 'internal server error',
}

export enum VendorError {
  NoMatchingServiceIds = 'no matching service ids found',
  InvalidVendor = 'invalid vendor',
}
