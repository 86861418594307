<div class="center row">
  <div>
    <h1 id="title">OH SNAP!</h1>
    <img src='assets/404.png' alt="ERROR 404"/>
    <h1>{{ errorInfo.code }}</h1>
    <h2>{{ errorInfo.label }}</h2>
    <p>{{ errorInfo.desc }}</p>
  </div>
</div>

