import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IProjectInfo } from 'src/app/model/i-project-info';
import { IServiceInfo } from 'src/app/model/i-vendor-info';
import { IProjectHistoryInfoResponse } from 'src/app/model/i-project-history-info';
import { ISubmitBatchRequest } from 'src/app/model/i-submit-batch-request';
import { IUpdateProjectRequest } from 'src/app/model/i-update-project-request';
import { IVendorServiceSelection } from 'src/app/model/i-vendor-service-selection';
import { BatchProjectInfo } from 'src/app/model/batch-project-info';
import { ProjectError } from 'src/app/model/error-enums';
import { environment } from '../../../environments/environment';
import { IProjectNotes } from '../../model/i-project-notes';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private _projectGuid: string;
  public get projectGuid(): string {
    return this._projectGuid;
  }

  private _batchGuid: string;
  public get batchGuid(): string {
    return this._batchGuid;
  }

  readonly projectSessionKey = 'projectGuid';
  readonly batchSessionKey = 'batchGuid';

  private readonly tpiWebApiUrl: string;

  private _existingNewProjectRequest: Observable<string> = null;

  constructor(protected http: HttpClient) {
    this.tpiWebApiUrl = environment.apiUrls.tpiWebApi;
  }

  public setProjectOrBatchGuidFromSessionStorage(): void {
    if (sessionStorage.getItem(this.projectSessionKey)) {
      this._projectGuid = sessionStorage.getItem(this.projectSessionKey);
    } else {
      this._batchGuid = sessionStorage.getItem(this.batchSessionKey);
    }
  }

  public requestProjectHistory(options?: {
    minCount?: number,
    startId?: string
  }): Observable<IProjectHistoryInfoResponse> {
    const url = new URL(`${ this.tpiWebApiUrl }/related_project_history_info/${ this.projectGuid }`);

    if (options?.minCount !== undefined) {
      url.searchParams.append('min_count', options.minCount.toString());
    } else {
      url.searchParams.append('min_count', '10');
    }

    if (options?.startId !== undefined) {
      url.searchParams.append('start_id', options.startId);
    }

    return this.http.get<IProjectHistoryInfoResponse>(url.toString());
  }

  // Single Project Functions

  public requestProjectInformation(): Observable<IProjectInfo> {
    const url = `/project_info/${ this._projectGuid }`;
    return this.http.get<IProjectInfo>(this.tpiWebApiUrl + url);
  }

  public requestProjectNotes(projectId): Observable<IProjectNotes> {
    const url = `/project_notes/${ projectId }`;
    return this.http.get<IProjectNotes>(this.tpiWebApiUrl + url);
  }

  public requestExistingProjectsVendorService(): Observable<IServiceInfo> {
    const url = `/existing_projects_vendor_service/${ this._projectGuid }`;
    return this.http.get<IServiceInfo>(this.tpiWebApiUrl + url);
  }

  public submitServiceRequest(selection: IVendorServiceSelection, projectInfo: IProjectInfo): Observable<HttpResponse<void>> {
    const service = selection.service;

    projectInfo.type = service.type;
    projectInfo.principalId = selection.vendor.principalId;

    const request: IUpdateProjectRequest = {
      projectInfo: projectInfo, vendorServiceId: service.serviceId
    }

    return this.http.post<void>(`${ this.tpiWebApiUrl }/update_project`, request, { observe: 'response' })
      .pipe(mergeMap(() => {
        if (service.availabilityCheck) {
          return this.getAvailabilityForProjectId(selection, this._projectGuid);
        } else {
          return of('skipped');
        }
      }), mergeMap(availCheckResult => {
        if (availCheckResult !== 'skipped' && !availCheckResult) {
          return throwError(new HttpErrorResponse({
            error: { message: ProjectError.NoAvailability }
          }));
        }
        return this.http.post<void>(`${ this.tpiWebApiUrl }/submit_project/${ this._projectGuid }`, null, { observe: 'response' });
      }));
  }

  private getAvailabilityForProjectId(selection: IVendorServiceSelection, projectId: string): Observable<boolean> {
    const url = new URL(`${ this.tpiWebApiUrl }/availability/${ projectId }`);
    url.searchParams.append('vendor', selection.vendor.displayName);
    url.searchParams.append('service_type', selection.service.type);

    return this.http.get<any>(url.toString()).pipe(map(value => !!value && !!value?.available), catchError(err => {
      console.error(`Error calling availability end point for project id ${ projectId }`);
      console.error(err);
      return of(false);
    }));
  }

// Batch Project Functions

  public requestBatchInformation(): Observable<IProjectInfo[]> {
    const url = `/batch_info/${ this._batchGuid }`;
    return this.http.get<IProjectInfo[]>(this.tpiWebApiUrl + url);
  }

  public checkAvailabilityForBatch(selection: IVendorServiceSelection, batchProjectInfo: BatchProjectInfo): Observable<{
    availableProjects: IProjectInfo[],
    unavailableProjects: IProjectInfo[]
  }> {
    if (!selection.service.availabilityCheck) {
      return of({
        availableProjects: batchProjectInfo.projects, unavailableProjects: [],
      });
    }

    const url = new URL(`${ this.tpiWebApiUrl }/availability_batch/${ this.batchGuid }`);
    url.searchParams.append('vendor', selection.vendor.displayName);
    url.searchParams.append('service_type', selection.service.type);

    return this.http.get<Record<string, any>>(url.toString()).pipe(map(res => {

      const projectRecord: Record<string, IProjectInfo> = {};
      for (const project of batchProjectInfo.projects) {
        projectRecord[project.id] = project;
      }

      const availableProjects: IProjectInfo[] = [];
      const unavailableProjects: IProjectInfo[] = [];

      for (const [projectId, availabilityResult] of Object.entries(res)) {
        if (!availabilityResult || !availabilityResult?.available) {
          unavailableProjects.push(projectRecord[projectId]);
        } else {
          availableProjects.push(projectRecord[projectId]);
        }
      }

      return {
        availableProjects, unavailableProjects,
      };
    }), catchError(err => {
      console.error(`Error calling availability end point for batch id ${ this.batchGuid }`);
      console.error(err);
      return of({
        availableProjects: [], unavailableProjects: batchProjectInfo.projects
      });
    }));
  }

  public submitBatchRequest(batchProjectInfo: BatchProjectInfo, vendorServiceId: string): Observable<HttpResponse<IProjectInfo[]>> {
    const request: ISubmitBatchRequest = {
      projects: batchProjectInfo.projects, vendorServiceId: vendorServiceId
    };

    return this.http.post<IProjectInfo[]>(`${ this.tpiWebApiUrl }/submit_batch`, request, { observe: 'response' });
  }

  public requestNewProjectURL(): Observable<string> {
    if (!this._existingNewProjectRequest) {
      this._existingNewProjectRequest = this.http.post(`${ this.tpiWebApiUrl }/create_project/${ this._projectGuid }`, null, { responseType: 'text' })
        .pipe(map(value => {
          // send back to local url
          return `${ window.location.origin }/?project=${ new URL(value).searchParams.get('project') }`;
        }));
    }
    return this._existingNewProjectRequest;
  }
}
