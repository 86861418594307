import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ProjectService } from '../../shared/services/project.service';
import { RouteEnum } from '../../shared/enums/routes.enum';

@Component({
  selector: 'app-authorize-waiting',
  templateUrl: './authorize-waiting.component.html',
  styleUrls: ['./authorize-waiting.component.scss'],
})
export class AuthorizeWaitingComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private router: Router,
              private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.setProjectOrBatchGuidFromSessionStorage();

    this.subscriptions.push(this.authService.tokenReceived.subscribe(
      (valid: boolean) => {
        if (valid) {
          !!this.projectService.batchGuid ? this.router.navigate([RouteEnum.BATCH_REQUEST]) : this.router.navigate([RouteEnum.REQUEST]);
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
