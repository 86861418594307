import { NgModule } from '@angular/core';
import { BatchConfirmInformationComponent } from './batch-confirm-information/batch-confirm-information.component';
import { BatchStepperPagesContainerComponent } from './batch-stepper-pages-container/batch-stepper-pages-container.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '../../shared/shared.module';
import { ProjectServiceRequestModule } from '../project-service-request/project-service-request.module';
import { BatchServiceRequestPageComponent } from './batch-service-request-page.component';
import { BatchRequestCompleteComponent } from './batch-request-complete/batch-request-complete.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
      BatchConfirmInformationComponent,
      BatchStepperPagesContainerComponent,
      BatchServiceRequestPageComponent,
      BatchRequestCompleteComponent,
    ],
    imports: [
      SharedModule,
      ProjectServiceRequestModule,
      MatStepperModule,
      CommonModule,
      BrowserModule,
      TranslateModule,
      MatStepperModule,
      MatIconModule,
      MatButtonModule,
      MatSelectModule,
      MatCardModule,
      MatProgressSpinnerModule,
      FormsModule,
      ReactiveFormsModule,
      MatInputModule,
      MatCheckboxModule,
      MatDialogModule,
      MatDividerModule,
      MatTableModule,
      MatCardModule,
    ]
  }
)
export class BatchServiceRequestModule {
}
