import { Component } from '@angular/core';

@Component({
  selector: 'app-error-vendor-service-ids',
  templateUrl: './error-vendor-service.component.html',
  styleUrls: ['./error-vendor-service.component.scss']
})
export class ErrorVendorServiceComponent {
  constructor() { }
}
