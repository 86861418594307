import { Injectable, ModuleWithProviders } from '@angular/core';
import { OAuthModule, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { IClaims } from '../interfaces/i-claims';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _tokenReceived = new BehaviorSubject(false);
  public tokenReceived = this._tokenReceived.asObservable();
  public authSessionKey = 'authorizing';

  constructor(private authService: OAuthService) {
  }

  configure(): void {
    this.authService.configure(environment.authConfig);
    // this.authService.setupAutomaticSilentRefresh();
    this.authService.setStorage(localStorage);

    this.authService.events.subscribe(event => {
      if (event instanceof OAuthSuccessEvent) {
        if (event.type === 'token_received') {
          this._tokenReceived.next(true);
        }
      }
    });
  }

  tryLogin(): Promise<boolean> {
    return this.authService.loadDiscoveryDocumentAndTryLogin();
  }

  logOut(): void {
    this.authService.logOut();
  }

  getClaims(): IClaims {
    return this.authService.getIdentityClaims() as IClaims;
  }

  isAuthenticated(): boolean {
    return this.authService.hasValidIdToken() && this.authService.hasValidAccessToken();
  }

  initLogin(loginEmail?: string): void {
    sessionStorage.setItem(this.authSessionKey, 'true');
    if (loginEmail) {
      this.authService.initCodeFlow(null, loginEmail);
    } else {
      this.authService.initCodeFlow();
    }
  }

  initGoogleLogin(): void {
    environment.authConfig.customQueryParams.acr_values = 'idp:google';
    this.authService.initCodeFlow();
  }

  getAccessToken(): string {
    return this.authService.getAccessToken();
  }
}

export class MockAuthModule {
  static forRoot(): ModuleWithProviders<any> {
    return OAuthModule.forRoot();
  }
}
