import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IServiceInfo, IVendorInfo } from '../../../model/i-vendor-info';
import { IProjectInfo } from '../../../model/i-project-info';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';
import { Countries, States, StateOrProvince } from '../../../shared/enums/locations.enum';
import { WhiteSpaceValidator } from '../../../shared/validators/white-space.validator';

@Component({
  selector: 'app-confirm-information',
  templateUrl: './confirm-information.component.html',
  styleUrls: ['./confirm-information.component.scss']
})
export class ConfirmInformationComponent implements OnInit {

  @Input()
  initialProjectInformation: IProjectInfo;

  _vendorServiceSelection: IVendorServiceSelection;
  @Input() set vendorServiceSelection(value: IVendorServiceSelection) {
    this._vendorServiceSelection = value;
    this.init();
  }_


  @Output()
  confirmation = new EventEmitter<IProjectInfo>();

  @Output()
  cancel = new EventEmitter<void>();

  countries = Countries;
  form: FormGroup;
  displayContactInformation = false;
  contactInformationRequired = false;
  displayIsPrimaryCheckboxes = false;
  displayPhoneEmailFields = false;
  states = States.US;
  stateOrProvince = StateOrProvince;
  showCustomPhoneTypeField = false;

  phoneTypes = [
    'Home',
    'Business',
    'Cellular',
    'Fax',
    'Pager',
    'Custom'
  ];

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.setFormStates();
    this.setContactInformationVisibility(this.initialProjectInformation, this._vendorServiceSelection.service);
    this.setForm(this.initialProjectInformation);
    this.loadProjectInfo(this.initialProjectInformation);

  }

  private setFormStates(): void {
    this.states = this.initialProjectInformation.country in States ? States[this.initialProjectInformation.country] : States.US;
    this.initialProjectInformation.country = this.initialProjectInformation.country in States ? this.initialProjectInformation.country : 'US';
  }

  private setContactInformationVisibility(projectInformation: IProjectInfo, service: IServiceInfo): void {
    // no "includeClaimantProfiles" or profile match
    this.contactInformationRequired = service.propertyOwnerContactFieldsRequired;
    this.displayContactInformation = service.includePolicyHolderData;
    this.displayIsPrimaryCheckboxes = service.includePolicyHolderData;
    this.displayPhoneEmailFields = true;

    if (!!service.includeClaimantProfiles && !service.includePolicyHolderData) {
      const profiles = service.includeClaimantProfiles.split('|');
      if (profiles.some(p => p === projectInformation.profile)) {
        this.displayContactInformation = true;
        this.displayIsPrimaryCheckboxes = false;
        this.displayPhoneEmailFields = false;
      }
    }
  }

  private setForm(projectInformation: IProjectInfo): void {
    this.form = this.fb.group({
      claim: this.fb.group({
        claimNum: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed, Validators.minLength(3)]]
      }),
      location: this.fb.group({
        street: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed, Validators.minLength(3)]],
        city: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed, Validators.minLength(2)]],
        country: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed]],
        state: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed]],
        zip: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed, Validators.minLength(5)]]
      }),
      claimRepContact: this.fb.group({
        primaryContact: [Boolean(!projectInformation?.propertyOwnerContactInfo?.primaryContact)],
        name: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed]],
        phone: ['', [Validators.required, WhiteSpaceValidator.noSpaceAllowed]],
        extension: [''],
        email: ['', [Validators.required, Validators.email, WhiteSpaceValidator.noSpaceAllowed]]
      }),
      note: [''],
    });

    if (this.displayContactInformation) {
      if (this.displayPhoneEmailFields) {
        this.form.addControl('propertyOwnerContact', this.fb.group({
          primaryContact: [Boolean(projectInformation?.propertyOwnerContactInfo?.primaryContact)],
          name: [''],
          phone: [''],
          extension: [''],
          phoneType: [''],
          email: ['', Validators.email]
        }));
      } else {
        this.form.addControl('propertyOwnerContact', this.fb.group({
          primaryContact: [Boolean(projectInformation?.propertyOwnerContactInfo?.primaryContact)],
          name: [''],
        }));
      }

      if (this.contactInformationRequired) {
        this.form.get('propertyOwnerContact').get('name').addValidators([Validators.required]);
        this.form.get('propertyOwnerContact').get('phone')?.addValidators([Validators.required]);
        this.form.get('propertyOwnerContact').get('email')?.addValidators([Validators.required]);
      }

      this.form.get('claimRepContact.primaryContact').valueChanges.subscribe(value => {
        this.form.get('propertyOwnerContact.primaryContact').setValue(!value, { emitEvent: false });
      });

      this.form.get('propertyOwnerContact.primaryContact').valueChanges.subscribe(value => {
        this.form.get('claimRepContact.primaryContact').setValue(!value, { emitEvent: false });
      });
    }

    this.form.markAllAsTouched();
  }

  private loadProjectInfo(projInfo: IProjectInfo): void {
    if (!this.form) {
      return;
    }

    this.form.patchValue({
      claim: {
        claimNum: projInfo.claimNum
      },
      location: {
        street: projInfo.street,
        city: projInfo.city,
        country: projInfo.country,
        state: projInfo.state,
        zip: projInfo.zip
      },
      claimRepContact: {
        name: projInfo.claimRepContactInfo?.name,
        phone: projInfo.claimRepContactInfo?.phone,
        extension: projInfo.claimRepContactInfo?.extension,
        email: projInfo.claimRepContactInfo?.email,
      },
      propertyOwnerContact: this.displayPhoneEmailFields ? {
        name: projInfo.propertyOwnerContactInfo?.name,
        phone: projInfo.propertyOwnerContactInfo?.phones?.[0]?.number,
        extension: projInfo.propertyOwnerContactInfo?.phones?.[0]?.extension,
        phoneType: projInfo.propertyOwnerContactInfo?.phones?.[0]?.type,
        email: projInfo.propertyOwnerContactInfo?.email,
      } : {
        name: projInfo.propertyOwnerContactInfo?.name,
      },
      note: projInfo.note,
    });
  }

  onPhoneTypeChange() : void {
    const group = this.form.get('propertyOwnerContact') as FormGroup;

    if (this.phoneTypeIsCustom()) {
      group.addControl('customPhoneType', new FormControl('', Validators.required));
      this.showCustomPhoneTypeField = true;
    } else {
      group.removeControl('customPhoneType');
      this.showCustomPhoneTypeField = false;
    }
  }

  phoneTypeIsCustom(): boolean {
    return this.form.get('propertyOwnerContact').get('phoneType').value === 'Custom';
  }

  onCountryChange(country: string): void {
    if (country != null) {
      this.states = States[country];
      this.form.patchValue({
        location: {
          state: null
        }
      });
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      // use values from initial info, then overwrite those with the possible new info from form, then contact infos...
      const result: IProjectInfo = {
        ...this.initialProjectInformation,
        ...this.form.get('location').value,
        claimRepContactInfo: this.form.get('claimRepContact').value,
        note: this.form.get('note').value,
        claimNum: this.form.get('claim').get('claimNum').value,
      };

      if (this.displayContactInformation) {
        const formValue = this.form.get('propertyOwnerContact').value as {
          primaryContact: boolean, name: string, phone?: string, extension?: string, phoneType?: string, customPhoneType?: string, email?: string
        };

        result.propertyOwnerContactInfo = this.displayPhoneEmailFields ? {
          primaryContact: formValue.primaryContact,
          name: formValue.name,
          email: formValue.email,
          phones: [{
            type: formValue.phoneType !== 'Custom' ? formValue.phoneType : formValue.customPhoneType,
            number: formValue.phone,
            extension: formValue.extension
          }]
        } : {
          primaryContact: formValue.primaryContact,
          name: formValue.name,
          email: this.initialProjectInformation.propertyOwnerContactInfo.email,
          phones: this.initialProjectInformation.propertyOwnerContactInfo.phones
        };
      } else if (!!result.propertyOwnerContactInfo) {
        delete result.propertyOwnerContactInfo;
      }

      this.confirmation.emit(result);
    }
  }
}
