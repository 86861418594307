<div class="vendor-grid">
    <mat-card
        appearance="outlined"
        *ngFor="let vendor of vendorList"
        class="vendor-container"
    >
        <ng-container *ngIf="!!vendorList; else spinner">
            <app-vendor-full-logo
                class="logo"
                [vendor]="vendor"
                [alignment]="windowWidth <= alignmentBreakpoint ? 'center' : 'left'"
            ></app-vendor-full-logo>
            <mat-form-field class="services">
                <mat-label>{{ 'REQUEST-SERVICE' | translate }}</mat-label>
                <mat-select>
                    <mat-option
                        *ngFor="let service of vendor.availableServices"
                        (click)="onOptionClick({vendor, service})"
                    >
                        <ng-container *ngIf="service.serviceDisplayName; else defaultDisplayName">
                            {{ service.serviceDisplayName }}
                        </ng-container>
                        <ng-template #defaultDisplayName>
                            {{ 'VENDOR-SERVICE.' + service.type | translate }}
                        </ng-template>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </mat-card>
</div>

<ng-template #spinner>
    <app-center-spinner></app-center-spinner>
</ng-template>
