import { Component, Input } from '@angular/core';
import { IVendorInfo } from '../../../model/i-vendor-info';

@Component({
  selector: 'app-vendor-icon-logo',
  templateUrl: './vendor-icon-logo.component.html',
  styleUrls: ['./vendor-icon-logo.component.scss']
})
export class VendorIconLogoComponent {

  @Input()
  vendor: Pick<IVendorInfo, 'displayName' | 'iconImageSource'>;

  constructor() { }
}
