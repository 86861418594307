import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';
import { ProjectError } from '../../../model/error-enums';

@Component({
  selector: 'app-request-complete',
  templateUrl: './request-complete.component.html',
  styleUrls: ['./request-complete.component.scss'],
})
export class RequestCompleteComponent implements OnInit {

  @Input()
  vendorServiceSelection: IVendorServiceSelection;

  @Input()
  requestStatus: 'pending' | 'failed' | 'success' = 'pending';

  @Input()
  errorMessage?: ProjectError;

  @Output()
  resetForm = new EventEmitter<void>();

  @Output()
  makeAnotherRequest = new EventEmitter<void>();

  showButtonSpinner = false;

  constructor() { }

  ngOnInit(): void {
  }

  getErrorTranslateKey(): string {
    switch (this.errorMessage) {
      case ProjectError.AlreadySubmitted:
        return 'PROJECT-ERROR.ALREADY-SUBMITTED';
      case ProjectError.NoAvailability:
        return 'PROJECT-ERROR.AVAILABILITY';
      default:
        return 'PROJECT-ERROR.GENERIC';
    }
  }

  shouldShowResetButton(): boolean {
    return this.requestStatus !== 'pending';

  }
  onResetButtonClick(): void {
    if (this.errorMessage === ProjectError.AlreadySubmitted || this.requestStatus === 'success') {
      // make a new request instead of going back when there is an
      // already submitted error OR the submission was successful
      this.makeAnotherRequest.emit();
      this.showButtonSpinner = true;
    } else {
      this.resetForm.emit();
    }
  }

  getResetButtonTranslateKey(): string {
    if (this.errorMessage === ProjectError.AlreadySubmitted || this.requestStatus === 'success') {
      return 'REQUEST-AGAIN';
    }
    return 'GO-BACK';
  }
}
