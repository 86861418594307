<ng-container *ngIf="!!batchProjectInfo && vendorServiceStatus !== 'loading'; else spinner">
    <mat-card
            appearance="outlined"
            *ngIf="!shouldShowRequestSteps"
    >
        {{ 'NO-SUBMITTABLE-PROJECTS-IN-BATCH' | translate }}
    </mat-card>
    <app-batch-stepper-pages-container
            *ngIf="shouldShowRequestSteps && vendorServiceStatus === 'loaded'"
            [batchProjectInfo]="batchProjectInfo"
            (requestComplete)="onRequestComplete()"
    ></app-batch-stepper-pages-container>
    <app-error-vendor-service-ids
            *ngIf="shouldShowRequestSteps && vendorServiceStatus === 'error'"
    ></app-error-vendor-service-ids>
</ng-container>

<ng-template #spinner>
    <app-center-spinner></app-center-spinner>
</ng-template>
