<mat-stepper
    #stepper
    class="stepper-pages-container"
    [linear]="true"
    (selectionChange)="onStepSelectionChange($event)"
    [orientation]="windowWidth >= orientationBreakpoint ? 'horizontal' : 'vertical'"
>
    <mat-step [editable]="stepper.selectedIndex < 2" [completed]="false">
        <ng-template matStepLabel>{{ 'STEP.SELECT-VENDOR.LABEL' | translate }}</ng-template>
        <section>
            <app-select-vendor
                    [vendorList]="vendors"
                    (vendorSelected)="onVendorSelected($event)"
            ></app-select-vendor>
        </section>
    </mat-step>
    <mat-step [editable]="stepper.selectedIndex < 2" [completed]="stepper.selectedIndex > 1">
        <ng-template matStepLabel>{{ 'STEP.CONFIRM.LABEL' | translate }}</ng-template>
        <section>
            <app-batch-confirm-information *ngIf="batchProjectInfo?.projects && vendorServiceSelection"
                    [initialProjectInformation]="batchProjectInfo"
                    [vendorServiceSelection]="vendorServiceSelection"
                    (confirmation)="onConfirmation($event)"
                    (cancel)="onConfirmCancel()"
            ></app-batch-confirm-information>
        </section>
    </mat-step>
    <mat-step [editable]="false" [completed]="false">
        <ng-template matStepLabel>{{ 'STEP.COMPLETE.LABEL' | translate }}</ng-template>
        <section>
            <app-batch-request-complete
                [vendorServiceSelection]="vendorServiceSelection"
                [requestStatus]="requestStatus"
                [errorMessage]="requestErrorMessage"
                [failedProjects]="failedProjects"
                [successfulProjects]="successfulProjects"
            ></app-batch-request-complete>
        </section>
    </mat-step>
</mat-stepper>
