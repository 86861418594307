import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IVendorInfo } from '../../../model/i-vendor-info';

@Component({
  selector: 'app-vendor-full-logo',
  templateUrl: './vendor-full-logo.component.html',
  styleUrls: ['./vendor-full-logo.component.scss']
})
export class VendorFullLogoComponent implements AfterViewInit, OnChanges {
  @Input()
  vendor: Pick<IVendorInfo, 'displayName' | 'logoImageSource'>;

  @Input()
  alignment: 'left' | 'center' | 'right' = 'left';

  @ViewChild('logoImg')
  logoImgElement: ElementRef<HTMLImageElement>;

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngAfterViewInit(): void {
    this.setImgAlign(this.alignment);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.alignment) {
      this.setImgAlign(this.alignment);
    }
  }

  private setImgAlign(align: VendorFullLogoComponent['alignment']): void {
    this.elementRef?.nativeElement.style.setProperty('--alignProp', align);
  }
}
