import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IVendorInfo } from '../../../model/i-vendor-info';
import { IVendorServiceSelection } from '../../../model/i-vendor-service-selection';

@Component({
  selector: 'app-select-vendor',
  templateUrl: './select-vendor.component.html',
  styleUrls: ['./select-vendor.component.scss']
})
export class SelectVendorComponent implements OnInit {

  windowWidth: number;
  alignmentBreakpoint = 450;

  @Input()
  vendorList: IVendorInfo[];

  @Output()
  vendorSelected = new EventEmitter<IVendorServiceSelection>();

  constructor() {
  }

  ngOnInit(): void {
    this.onWindowResize();
  }

  onOptionClick(selection: IVendorServiceSelection): void {
    if (!!selection) {
      this.vendorSelected.emit(selection);
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.windowWidth = window.innerWidth;
  }
}
