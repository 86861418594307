import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { IProjectInfo } from 'src/app/model/i-project-info';
import { IServiceInfo, IVendorInfo } from 'src/app/model/i-vendor-info';

@Component({
  selector: 'app-existing-project-status',
  templateUrl: './existing-project-status.component.html',
  styleUrls: ['./existing-project-status.component.scss']
})
export class ExistingProjectStatusComponent implements OnInit {

  @Input()
  projectInfo: IProjectInfo = null;

  @Output()
  makeAnotherRequest = new EventEmitter<void>();

  serviceInfo: IServiceInfo = null;
  showButtonSpinner = false;

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    this.projectService.requestExistingProjectsVendorService().subscribe(result => this.serviceInfo = result);
  }

  getVendorInfo(): Pick<IVendorInfo, 'displayName'> {
    return { displayName: this.serviceInfo ? this.serviceInfo.customerIdentifier : '' };
  }

  onRequestAgain(): void {
    this.makeAnotherRequest.emit();
    this.showButtonSpinner = true;
  }
}
