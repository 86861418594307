<ng-container *ngIf="!!projectInfo && vendorServiceStatus !== 'loading'; else spinner">
    <app-existing-project-status
            *ngIf="!shouldShowRequestSteps"
            [projectInfo]="projectInfo"
            (makeAnotherRequest)="onMakeAnotherRequest()"
    ></app-existing-project-status>
    <app-stepper-pages-container
            *ngIf="shouldShowRequestSteps && vendorServiceStatus === 'loaded'"
            [projectInfo]="projectInfo"
            (requestComplete)="onRequestComplete()"
            (makeAnotherRequest)="onMakeAnotherRequest()"
    ></app-stepper-pages-container>
    <app-error-vendor-service-ids
            *ngIf="shouldShowRequestSteps && vendorServiceStatus === 'error'"
    ></app-error-vendor-service-ids>
    <mat-divider></mat-divider>
    <app-project-history-table></app-project-history-table>
</ng-container>

<ng-template #spinner>
    <app-center-spinner></app-center-spinner>
</ng-template>