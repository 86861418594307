// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --production` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authConfig: {
    issuer: 'https://betaidentity.verisk.com',
    redirectUri: window.location.origin + '/authorize',
    logoutUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '3dvi.third.party.services',
    responseType: 'code',
    scope: 'openid profile email offline_access',
    sessionChecksEnabled: false,
    showDebugInformation: true,
    customQueryParams: {
      ui_locales: 'en-US',
      acr_values: '',
    },
  },
  apiUrls: {
    tpiWebApi: 'https://api.test.3dvi.verisk.com/tpi-web-api'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
