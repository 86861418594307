import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { VendorService } from '../../shared/services/vendor.service';
import { environment } from '../../../environments/environment';
import { RouteEnum } from '../../shared/enums/routes.enum';
import { IProjectInfo } from '../../model/i-project-info';
import { Router } from '@angular/router';
import { ProjectStatus } from '../../model/project-status';
import { ProjectHistoryTableComponent } from '../../shared/components/project-history-table/project-history-table.component';

@Component({
  selector: 'app-project-service-request-page',
  templateUrl: './project-service-request-page.component.html',
  styleUrls: ['./project-service-request-page.component.scss']
})
export class ProjectServiceRequestPageComponent implements OnInit {

  projectInfo: IProjectInfo = null;

  shouldShowRequestSteps = true;

  @ViewChild(ProjectHistoryTableComponent)
  historyTable: ProjectHistoryTableComponent;

  vendorServiceStatus: 'loading' | 'loaded' | 'error' = 'loading';

  constructor(
    private projectService: ProjectService,
    private vendorService: VendorService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.projectService.requestProjectInformation().subscribe(res => {
      if (!environment.production) {
        // tslint:disable-next-line:no-console
        console.debug('project info loaded');
        // tslint:disable-next-line:no-console
        console.debug(res);
      }
      this.projectInfo = res;
      this.shouldShowRequestSteps = this.projectInfo.status === ProjectStatus.New;

      // vendor infos
      if (!!res.vendorServiceIds) {
        this.vendorService.requestVendorList(res.vendorServiceIds).subscribe(vendors => {
            if (!environment.production) {
              // tslint:disable-next-line:no-console
              console.debug(`${vendors.length} vendor infos loaded`);
              // tslint:disable-next-line:no-console
              console.debug(vendors);
            }
            this.vendorServiceStatus = 'loaded';
          }, () => {
            this.vendorServiceStatus = 'error';
          }
        );
      } else {
        console.error('project has no associated service ids');
        this.vendorServiceStatus = 'error';
      }
    }, () => {
      this.router.navigate([RouteEnum.PROJECT_ERROR]);
    });
  }

  onMakeAnotherRequest(): void {
    this.projectService.requestNewProjectURL().subscribe(res => {
      // Don't use the angular router, just reload the entire website
      window.location.assign(res);
    });
  }

  onRequestComplete(): void {
    this.historyTable.reloadData();
  }
}
