import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashPageComponent } from './auth/splash-page/splash-page.component';
import { AuthorizeWaitingComponent } from './auth/authorize-waiting/authorize-waiting.component';
import { SharedModule } from './shared/shared.module';
import { ErrorPageComponent } from './error-page/error-page.component';

import { environment } from '../environments/environment';
import { ProjectServiceRequestModule } from './pages/project-service-request/project-service-request.module';
import { BatchServiceRequestModule } from './pages/batch-service-request/batch-service-request.module';
import { HealthComponent } from './health/health.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/locale-', '.json');
}

@NgModule(
  {
    declarations: [
      AppComponent,
      ErrorPageComponent,
      SplashPageComponent,
      AuthorizeWaitingComponent,
      HealthComponent,
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      RouterModule,
      SharedModule,
      BrowserAnimationsModule,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        },
        defaultLanguage: 'en-US'
      }),
      OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: [environment.apiUrls.tpiWebApi],
          sendAccessToken: true
        }
      }),
      BrowserAnimationsModule,
      ProjectServiceRequestModule,
      BatchServiceRequestModule,
      MatButtonModule,
      MatIconModule,
      MatChipsModule,
    ],
    providers: [
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: 'outline',
          floatLabel: 'auto',
        }
      },
    ],
    bootstrap: [AppComponent],
  })
export class AppModule {
}
