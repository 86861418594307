import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectService } from '../../shared/services/project.service';
import { RouteEnum } from '../../shared/enums/routes.enum';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.hasOwnProperty('project') || params.hasOwnProperty('batchid')) {
        sessionStorage.clear();

        if (params.project) {
          sessionStorage.setItem(this.projectService.projectSessionKey, params.project);
        } else {
          sessionStorage.setItem(this.projectService.batchSessionKey, params.batchid);
        }
        this.authService.initLogin();
      } else if (
        Object.keys(params).length === 0 && // If there are invalid query params, then go to error page
        (!!sessionStorage.getItem(this.projectService.projectSessionKey)
          || !!sessionStorage.getItem(this.projectService.batchSessionKey))) {
        this.authService.initLogin();
      } else {
        this.router.navigate([RouteEnum.PROJECT_ERROR]);
        return;
      }
    });
  }
}
