import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmInformationComponent } from './confirm-information/confirm-information.component';
import { ExistingProjectStatusComponent } from './existing-project-status/existing-project-status.component';
import { RequestCompleteComponent } from './request-complete/request-complete.component';
import { StepperPagesContainerComponent } from './stepper-pages-container/stepper-pages-container.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ProjectServiceRequestPageComponent } from './project-service-request-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    ConfirmInformationComponent,
    ExistingProjectStatusComponent,
    RequestCompleteComponent,
    StepperPagesContainerComponent,
    ProjectServiceRequestPageComponent,
  ],
  imports: [
    SharedModule,
    MatStepperModule,
    CommonModule,
    BrowserModule,
    TranslateModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
  ],
})
export class ProjectServiceRequestModule { }
