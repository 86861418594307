import { AbstractControl, ValidationErrors } from '@angular/forms';

export class WhiteSpaceValidator {
  static noSpaceAllowed(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!value || value.trim() === '') {
      return { noSpaceAllowed: true };
    }

    return null;
  }
}
