import { Component, OnInit } from '@angular/core';
import { HealthService } from './health.service';
import { IHealthCheckResponse } from '../model/i-health-check-response';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {

  loading = true;
  error = false;
  healthCheckData: IHealthCheckResponse['data'] = null;
  pendingRequest: Subscription = null;

  constructor(private healthService: HealthService) {
  }

  ngOnInit(): void {
    this.runHealthCheck();
  }

  getApiUrl(): string {
    return  this.healthService.tpiWebApiUrl;
  }

  runHealthCheck(): void {
    if (!!this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }

    this.loading = true;

    this.pendingRequest = this.healthService.health().subscribe({
      next: (response) => {
        this.loading = false;
        this.error = false;
        this.healthCheckData = response.data;
      },
      error: () => {
        this.loading = false;
        this.error = true;
        this.healthCheckData = null;
      }
    });
  }

}
