import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {

  errorInfo = {
    code: '',
    label: '',
    desc: ''
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(next => {
      this.setErrorText(next.code);
    });
  }

  setErrorText(code: HttpStatusCode): void {
    switch (code) {
      case HttpStatusCode.BadRequest:
        this.errorInfo = {
          code: '400',
          label: 'Bad request',
          desc: ''
        };
        break;
      case HttpStatusCode.NotFound:
      default:
        this.errorInfo = {
          code: '404',
          label: 'Page not found',
          desc: 'The page you are looking for doesn\'t exist.'
        };
        break;
    }
  }
}
