<h2 class="label">{{ 'PROJECT-HISTORY.LABEL' | translate }}</h2>
<div class="scroll-area">

    <table mat-table
           [dataSource]="orderHistoryDisplayEntries" multiTemplateDataRows
           class="mat-elevation-z8">
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="vendorLogo">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let entry">
                <app-vendor-icon-logo
                        class="vendor-icon"
                        [vendor]="{ displayName: entry.vendorName, iconImageSource: entry.vendorIcon }">
                </app-vendor-icon-logo>
            </td>
        </ng-container>

        <ng-container matColumnDef="vendorName">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.VENDOR' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.vendorName }}</td>
        </ng-container>

        <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.SERVICE' | translate }}</th>
            <td mat-cell *matCellDef="let entry">
                <ng-container *ngIf="entry.serviceDisplayName; else defaultDisplayName">
                    {{ entry.serviceDisplayName }}
                </ng-container>
                <ng-template #defaultDisplayName>
                    {{ 'VENDOR-SERVICE.' + entry.service | translate }}
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.STATUS' | translate }}</th>
            <td mat-cell *matCellDef="let entry">
                {{ 'SERVICE-STATUS.' + entry.status | translate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="claimNumber">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.CLAIM-NUMBER' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.claimNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.DATE' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">{{ entry.dateUpdated ?? entry.dateCreated | intlDate }}</td>
        </ng-container>

        <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT-HISTORY.NOTES' | translate }}</th>
            <td mat-cell *matCellDef>
                <button>See Notes</button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-description" *ngFor="let note of projectNotes?.notes">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ note.subject | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>{{note.message}}</p>
                        </mat-expansion-panel>
                        <span class="example-element-description-attribution"></span>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="onRowExpandClick(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <button
            *ngIf="nextId !== undefined"
            class="load-more-button"
            mat-raised-button
            (click)="requestData()"
    >
        {{ 'PROJECT-HISTORY.LOAD-MORE' | translate}}
    </button>
</div>
