export const Countries = [
    {Abbreviation: 'CA', Name: 'Canada'},
    {Abbreviation: 'US', Name: 'United States'}
];

export const States = {
    CA: [
        {Abbreviation: 'AB', Name: 'Alberta'},
        {Abbreviation: 'BC', Name: 'British Columbia'},
        {Abbreviation: 'MB', Name: 'Manitoba'},
        {Abbreviation: 'NB', Name: 'New Brunswick'},
        {Abbreviation: 'NL', Name: 'Newfoundland and Labrador'},
        {Abbreviation: 'NT', Name: 'Northwest Territories'},
        {Abbreviation: 'NS', Name: 'Nova Scotia'},
        {Abbreviation: 'NU', Name: 'Nunavut'},
        {Abbreviation: 'ON', Name: 'Ontario'},
        {Abbreviation: 'PE', Name: 'Prince Edward Island'},
        {Abbreviation: 'QC', Name: 'Quebec'},
        {Abbreviation: 'SK', Name: 'Saskatchewan'},
        {Abbreviation: 'YT', Name: 'Yukon'},
    ],
    US: [
        {Abbreviation: 'AL', Name: 'Alabama'},
        {Abbreviation: 'AK', Name: 'Alaska'},
        {Abbreviation: 'AS', Name: 'American Samoa'},
        {Abbreviation: 'AZ', Name: 'Arizona'},
        {Abbreviation: 'AR', Name: 'Arkansas'},
        {Abbreviation: 'CA', Name: 'California'},
        {Abbreviation: 'CO', Name: 'Colorado'},
        {Abbreviation: 'CT', Name: 'Connecticut'},
        {Abbreviation: 'DE', Name: 'Delaware'},
        {Abbreviation: 'DC', Name: 'District Of Columbia'},
        {Abbreviation: 'FM', Name: 'Federated States Of Micronesia'},
        {Abbreviation: 'FL', Name: 'Florida'},
        {Abbreviation: 'GA', Name: 'Georgia'},
        {Abbreviation: 'GU', Name: 'Guam'},
        {Abbreviation: 'HI', Name: 'Hawaii'},
        {Abbreviation: 'ID', Name: 'Idaho'},
        {Abbreviation: 'IL', Name: 'Illinois'},
        {Abbreviation: 'IN', Name: 'Indiana'},
        {Abbreviation: 'IA', Name: 'Iowa'},
        {Abbreviation: 'KS', Name: 'Kansas'},
        {Abbreviation: 'KY', Name: 'Kentucky'},
        {Abbreviation: 'LA', Name: 'Louisiana'},
        {Abbreviation: 'ME', Name: 'Maine'},
        {Abbreviation: 'MH', Name: 'Marshall Islands'},
        {Abbreviation: 'MD', Name: 'Maryland'},
        {Abbreviation: 'MA', Name: 'Massachusetts'},
        {Abbreviation: 'MI', Name: 'Michigan'},
        {Abbreviation: 'MN', Name: 'Minnesota'},
        {Abbreviation: 'MS', Name: 'Mississippi'},
        {Abbreviation: 'MO', Name: 'Missouri'},
        {Abbreviation: 'MT', Name: 'Montana'},
        {Abbreviation: 'NE', Name: 'Nebraska'},
        {Abbreviation: 'NV', Name: 'Nevada'},
        {Abbreviation: 'NH', Name: 'New Hampshire'},
        {Abbreviation: 'NJ', Name: 'New Jersey'},
        {Abbreviation: 'NM', Name: 'New Mexico'},
        {Abbreviation: 'NY', Name: 'New York'},
        {Abbreviation: 'NC', Name: 'North Carolina'},
        {Abbreviation: 'ND', Name: 'North Dakota'},
        {Abbreviation: 'MP', Name: 'Northern Mariana Islands'},
        {Abbreviation: 'OH', Name: 'Ohio'},
        {Abbreviation: 'OK', Name: 'Oklahoma'},
        {Abbreviation: 'OR', Name: 'Oregon'},
        {Abbreviation: 'PW', Name: 'Palau'},
        {Abbreviation: 'PA', Name: 'Pennsylvania'},
        {Abbreviation: 'PR', Name: 'Puerto Rico'},
        {Abbreviation: 'RI', Name: 'Rhode Island'},
        {Abbreviation: 'SC', Name: 'South Carolina'},
        {Abbreviation: 'SD', Name: 'South Dakota'},
        {Abbreviation: 'TN', Name: 'Tennessee'},
        {Abbreviation: 'TX', Name: 'Texas'},
        {Abbreviation: 'UT', Name: 'Utah'},
        {Abbreviation: 'VT', Name: 'Vermont'},
        {Abbreviation: 'VI', Name: 'Virgin Islands'},
        {Abbreviation: 'VA', Name: 'Virginia'},
        {Abbreviation: 'WA', Name: 'Washington'},
        {Abbreviation: 'WV', Name: 'West Virginia'},
        {Abbreviation: 'WI', Name: 'Wisconsin'},
        {Abbreviation: 'WY', Name: 'Wyoming'}
    ]
};

export const StateOrProvince = {
    CA: 'FORM.LOCATION.PROVINCE',
    US: 'FORM.LOCATION.STATE'
};
