import { IProjectInfo } from './i-project-info';
import { IBatchFormResult } from './i-batch-form-result';
import { IVendorServiceSelection } from './i-vendor-service-selection';

export class BatchProjectInfo {
  public constructor(
    public projects: IProjectInfo[]
  ) {
  }

  public getVendorServiceIds(): Set<number> {
    const result: Set<number> = new Set();
    for (const p of this.projects) {
      for (const id of p.vendorServiceIds) {
        result.add(id);
      }
    }

    return result;
  }

  public updateInfoWithFormResult(batchFormResult: IBatchFormResult): void {
    for (const project of this.projects) {

      project.claimRepContactInfo.name = batchFormResult.claimRepContactInfo.name;
      project.claimRepContactInfo.email = batchFormResult.claimRepContactInfo.email;
      project.claimRepContactInfo.phone = batchFormResult.claimRepContactInfo.phone;
      project.claimRepContactInfo.extension = batchFormResult.claimRepContactInfo.extension;

      // Only the primaryContact flag on the propertyOwnerContactInfo matters to the backend
      if (!!project.propertyOwnerContactInfo) {
        project.propertyOwnerContactInfo.primaryContact = !batchFormResult.claimRepContactInfo.primaryContact;
      }

      project.note = batchFormResult.note;
    }
  }

  public updateVendorServiceSelection(selection: IVendorServiceSelection): void {
    for (const project of this.projects) {
      project.type = selection.service.type;
      project.principalId = selection.vendor.principalId;
    }
  }
}
