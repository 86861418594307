import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { HeaderComponent } from './components/header/header.component';
import { VendorFullLogoComponent } from './components/vendor-full-logo/vendor-full-logo.component';
import { LayoutComponent } from './components/layout/layout.component';
import { VendorIconLogoComponent } from './components/vendor-icon-logo/vendor-icon-logo.component';
import { CenterSpinnerComponent } from './components/center-spinner/center-spinner.component';
import { IntlDatePipe } from './pipes/intl-date.pipe';
import { ProjectHistoryTableComponent } from './components/project-history-table/project-history-table.component';
import { SelectVendorComponent } from './components/select-vendor/select-vendor.component';
import { ErrorVendorServiceComponent } from './components/error-vendor-service/error-vendor-service.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

const exportModules = [
  MatProgressSpinnerModule,
];

const modules = [
  ...exportModules,
  CommonModule,
  RouterModule,
  TranslateModule,
  MatIconModule,
  MatTableModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatButtonModule,
  MatExpansionModule,
];

const components = [
  HeaderComponent,
  LayoutComponent,
  VendorFullLogoComponent,
  VendorIconLogoComponent,
  CenterSpinnerComponent,
  ProjectHistoryTableComponent,
  SelectVendorComponent,
  ErrorVendorServiceComponent,
];

@NgModule({
  imports: [modules],
  declarations: [components, IntlDatePipe],
  exports: [components, exportModules, IntlDatePipe],
})
export class SharedModule { }
