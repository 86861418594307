<mat-card appearance="outlined">
    <mat-card-header class="card-header">
        <div class="card-header-row">
            <span class="title">{{ 'CONFIRM-INFORMATION.TITLE' | translate }}</span>
        </div>
        <div class="card-header-row">
            <span class="label">{{ 'CONFIRM-INFORMATION.SELECTED-VENDOR' | translate }}</span>
            <app-vendor-full-logo
                    class="logo"
                    *ngIf="vendorServiceSelection"
                    [vendor]="vendorServiceSelection?.vendor"
            ></app-vendor-full-logo>
        </div>
        <div class="card-header-row">
            <span class="label">{{ 'CONFIRM-INFORMATION.SELECTED-SERVICE' | translate }}</span>
            <span>{{ 'VENDOR-SERVICE.' + vendorServiceSelection?.service.type | translate }}</span>
        </div>
    </mat-card-header>

    <mat-card-content>
            <div class="info-form form-location field-disabled">
                <h2 class="form-label">{{ 'FORM.CLAIM.LABEL' | translate }}</h2>
                <mat-form-field>
                    <mat-label>{{ 'FORM.CLAIM.CLAIM_NUMBER' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
            </div>
            <div class="info-form form-location field-disabled">
                <h2 class="form-label">{{ 'FORM.LOCATION.LABEL' | translate}}</h2>
                <mat-form-field class="form-street">
                    <mat-label>{{ 'FORM.LOCATION.STREET' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-city">
                    <mat-label>{{ 'FORM.LOCATION.CITY' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-state">
                    <mat-label>{{ stateOrProvince[country] | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-zip">
                    <mat-label>{{ 'FORM.LOCATION.ZIP' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-country">
                    <mat-label>{{ 'FORM.LOCATION.COUNTRY' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
            </div>
        <form [formGroup]="form">
            <div class="info-form form-contact" formGroupName="claimRepContact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.CONTACT.CLAIM-REP' | translate }}</div>
                </h2>
                <div class="form-primary-contact" *ngIf="displayIsPrimaryCheckboxes">
                    <mat-checkbox formControlName="primaryContact" (change)="togglePrimaryOwner($event)">
                        {{ 'FORM.CONTACT.IS-PRIMARY' | translate }}
                    </mat-checkbox>
                </div>
                <mat-form-field class="form-name">
                    <mat-label>{{ 'FORM.CONTACT.NAME' | translate }}</mat-label>
                    <input type="text" matInput formControlName="name"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-phone">
                    <mat-label>{{ 'FORM.CONTACT.PHONE' | translate }}</mat-label>
                    <input type="text" matInput formControlName="phone"/>
                    <mat-error *ngIf="!form.get('claimRepContact').get('phone').value">{{ 'FORM.REQUIRED' | translate }}</mat-error>
                    <mat-error *ngIf="form.get('claimRepContact').get('phone').value">{{ 'FORM.INVALID_PHONE' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-phone-ext">
                    <mat-label>{{ 'FORM.CONTACT.PHONE-EXT' | translate }} {{ 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="extension"/>
                </mat-form-field>
                <mat-form-field class="form-email">
                    <mat-label>{{ 'FORM.CONTACT.EMAIL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="email"/>
                    <mat-error *ngIf="!form.get('claimRepContact').get('email').value">{{ 'FORM.REQUIRED' | translate }}</mat-error>
                    <mat-error *ngIf="form.get('claimRepContact').get('email').value">{{ 'FORM.INVALID_EMAIL' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="displayContactInformation" class="info-form form-contact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.CONTACT.OWNER' | translate }}</div>
                </h2>
                <div class="form-primary-contact" *ngIf="displayIsPrimaryCheckboxes">
                    <mat-checkbox [checked]="propertyOwnerCheckboxIsChecked" (change)="togglePrimaryOwner($event)">
                        {{ 'FORM.CONTACT.IS-PRIMARY' | translate }}
                    </mat-checkbox>
                </div>
                <mat-form-field class="form-name field-disabled">
                    <mat-label>{{ 'FORM.CONTACT.NAME' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-phone field-disabled" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.PHONE' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-phone-ext field-disabled" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.PHONE-EXT' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
                <mat-form-field class="form-email field-disabled" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.EMAIL' | translate }}</mat-label>
                    <input type="text" [disabled] matInput [value]="defaultBatchFieldValue"/>
                </mat-form-field>
            </div>
            <div class="info-form form-contact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.NOTE.LABEL' | translate }}</div>
                </h2>
                <mat-form-field class="form-note">
                    <mat-label>{{ 'FORM.NOTE.NOTE' | translate }}</mat-label>
                    <textarea class="text-area" matInput formControlName="note"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="warn" (click)="cancel.emit()">
            {{ 'FORM.CANCEL' | translate }}
        </button>
        <button mat-raised-button
                color="primary"
                (click)="onSubmit()"
                [disabled]="!form.valid">
            {{ 'FORM.SUBMIT' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
