import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthorizingGuard } from './shared/guards/authorizing-guard.service';
import { RouteEnum } from './shared/enums/routes.enum';
import { SplashPageComponent } from './auth/splash-page/splash-page.component';
import { AuthorizeWaitingComponent } from './auth/authorize-waiting/authorize-waiting.component';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ProjectInfoGuard } from './shared/guards/project-info-guard.service';
import { ProjectServiceRequestPageComponent } from './pages/project-service-request/project-service-request-page.component';
import { HttpStatusCode } from '@angular/common/http';
import { BatchServiceRequestPageComponent } from './pages/batch-service-request/batch-service-request-page.component';
import { HealthComponent } from './health/health.component';

const routes: Routes = [
  { path: '', component: SplashPageComponent },
  { path: RouteEnum.AUTHORIZE, canActivate: [AuthorizingGuard], component: AuthorizeWaitingComponent },
  {
    path: '',
    canActivate: [AuthGuard, ProjectInfoGuard],
    component: LayoutComponent,
    children: [
        { path: RouteEnum.REQUEST, component: ProjectServiceRequestPageComponent },
        { path: RouteEnum.BATCH_REQUEST, component: BatchServiceRequestPageComponent }
    ]
  },
  { path: RouteEnum.PROJECT_ERROR, component: ErrorPageComponent, data: { code: HttpStatusCode.BadRequest } },
  { path: RouteEnum.HEALTH, component: HealthComponent},
  { path: '**', component: ErrorPageComponent, data: { code: HttpStatusCode.NotFound } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
