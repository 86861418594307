import { Component } from '@angular/core';

@Component({
  selector: 'app-center-spinner',
  templateUrl: './center-spinner.component.html',
  styleUrls: ['./center-spinner.component.scss']
})
export class CenterSpinnerComponent {

  constructor() { }

}
