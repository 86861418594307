import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizingGuard  {

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!!sessionStorage.getItem(this.authService.authSessionKey)) {
      sessionStorage.removeItem(this.authService.authSessionKey);
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
