import { Component, OnInit } from '@angular/core';
import { IProjectHistoryInfo } from '../../../model/i-project-history-info';
import { ProjectService } from '../../services/project.service';
import { IVendorInfo } from '../../../model/i-vendor-info';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IProjectNotes } from '../../../model/i-project-notes';
import { Observable } from 'rxjs';

interface IOrderHistoryDisplayEntry extends IProjectHistoryInfo {
  vendor?: IVendorInfo;
}

@Component({
  selector: 'app-project-history-table',
  templateUrl: './project-history-table.component.html',
  styleUrls: ['./project-history-table.component.scss'],
  animations: [trigger('detailExpand', [state('collapsed', style({
    height: '0px', minHeight: '0', display: 'none'
  })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),]),],
})
export class ProjectHistoryTableComponent implements OnInit {

  displayedColumns = ['vendorLogo', 'vendorName', 'service', 'action', 'claimNumber', 'date'];
  orderHistoryDisplayEntries: IOrderHistoryDisplayEntry[] = [];
  nextId: string = undefined;
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: IOrderHistoryDisplayEntry | null;
  projectNotes: IProjectNotes;

  constructor(private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.requestData();
  }

  requestData(): void {
    this.projectService.requestProjectHistory({ startId: this.nextId }).subscribe(res => {
      this.nextId = res.lastId;
      this.orderHistoryDisplayEntries = this.orderHistoryDisplayEntries.concat(res.list);
    });
  }

  requestProjectNotes(projectId: string): Observable<IProjectNotes> {
    return this.projectService.requestProjectNotes(projectId);
  }

  reloadData(): void {
    this.nextId = undefined;
    this.orderHistoryDisplayEntries = [];
    this.requestData();
  }

  onRowExpandClick(element): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.requestProjectNotes(element.projectId).subscribe(notes => {
      this.projectNotes = notes;

      if (notes.notes.length === 0) {
        this.projectNotes = {
          notes: [{ subject: 'No notes found', message: 'No notes found for this project' }]
        };
      }
    });
  }
}
