<mat-card appearance="outlined">
    <mat-card-header class="card-header">
        <div class="card-header-row">
            <span class="title">{{ 'CONFIRM-INFORMATION.TITLE' | translate }}</span>
        </div>
        <div class="card-header-row">
            <span class="label">{{ 'CONFIRM-INFORMATION.SELECTED-VENDOR' | translate }}</span>
            <app-vendor-full-logo
                class="logo"
                *ngIf="_vendorServiceSelection"
                [vendor]="_vendorServiceSelection?.vendor"
            ></app-vendor-full-logo>
        </div>
        <div class="card-header-row">
            <span class="label">{{ 'CONFIRM-INFORMATION.SELECTED-SERVICE' | translate }}</span>
            <ng-container *ngIf="_vendorServiceSelection?.service?.serviceDisplayName; else defaultServiceName">
                <span>{{ _vendorServiceSelection?.service?.serviceDisplayName }}</span>
            </ng-container>
            <ng-template #defaultServiceName>
                <span>{{ 'VENDOR-SERVICE.' + _vendorServiceSelection?.service.type | translate }}</span>
            </ng-template>
        </div>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="form">
            <div class="info-form form-location" formGroupName="claim">
                <h2 class="form-label">{{ 'FORM.CLAIM.LABEL' | translate }}</h2>
                <mat-form-field class="form-claim-num">
                    <mat-label>{{ 'FORM.CLAIM.CLAIM_NUMBER' | translate }}</mat-label>
                    <input type="text" matInput formControlName="claimNum">
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="info-form form-location" formGroupName="location">
                <h2 class="form-label">{{ 'FORM.LOCATION.LABEL' | translate}}</h2>
                <mat-form-field class="form-street">
                    <mat-label>{{ 'FORM.LOCATION.STREET' | translate }}</mat-label>
                    <input type="text" matInput formControlName="street"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-city">
                    <mat-label>{{ 'FORM.LOCATION.CITY' | translate }}</mat-label>
                    <input type="text" matInput formControlName="city"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-state">
                    <mat-label>{{ stateOrProvince[form.value.location.country] | translate }}</mat-label>
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of states"
                                    value="{{state.Abbreviation}}">{{state.Name}}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-zip">
                    <mat-label>{{ 'FORM.LOCATION.ZIP' | translate }}</mat-label>
                    <input type="text" matInput formControlName="zip"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-country">
                    <mat-label>{{ 'FORM.LOCATION.COUNTRY' | translate }}</mat-label>
                    <mat-select formControlName="country" (selectionChange)="onCountryChange($event.value)">
                        <mat-option *ngFor="let country of countries"
                                    value="{{country.Abbreviation}}">{{country.Name}}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="info-form form-contact" formGroupName="claimRepContact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.CONTACT.CLAIM-REP' | translate }}</div>
                </h2>
                <div class="form-primary-contact" *ngIf="displayIsPrimaryCheckboxes">
                    <mat-checkbox formControlName="primaryContact">
                        {{ 'FORM.CONTACT.IS-PRIMARY' | translate }}
                    </mat-checkbox>
                </div>
                <mat-form-field class="form-name">
                    <mat-label>{{ 'FORM.CONTACT.NAME' | translate }}</mat-label>
                    <input type="text" matInput formControlName="name"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-phone">
                    <mat-label>{{ 'FORM.CONTACT.PHONE' | translate }}</mat-label>
                    <input type="text" matInput formControlName="phone"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-phone-ext">
                    <mat-label>{{ 'FORM.CONTACT.PHONE-EXT' | translate }} {{ 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="extension"/>
                </mat-form-field>
                <mat-form-field class="form-email">
                    <mat-label>{{ 'FORM.CONTACT.EMAIL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="email"/>
                    <mat-error
                        *ngIf="!form.get('claimRepContact').get('email').value">{{ 'FORM.REQUIRED' | translate }}</mat-error>
                    <mat-error
                        *ngIf="form.get('claimRepContact').get('email').value">{{ 'FORM.INVALID_EMAIL' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="displayContactInformation" class="info-form form-contact" formGroupName="propertyOwnerContact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.CONTACT.OWNER' | translate }}</div>
                </h2>
                <div class="form-primary-contact" *ngIf="displayIsPrimaryCheckboxes">
                    <mat-checkbox formControlName="primaryContact">
                        {{ 'FORM.CONTACT.IS-PRIMARY' | translate }}
                    </mat-checkbox>
                </div>
                <mat-form-field class="form-owner-name">
                    <mat-label>{{ 'FORM.CONTACT.NAME' | translate }} {{ contactInformationRequired ? '' : 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="name"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-owner-phone" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.PHONE' | translate }} {{ contactInformationRequired ? '' : 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="phone"/>
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-owner-phone-ext" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.PHONE-EXT' | translate }} {{ 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="extension"/>
                </mat-form-field>
                <mat-form-field class="form-owner-phone-type" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.PHONE-TYPE' | translate }} {{ 'FORM.OPTIONAL' | translate }}</mat-label>
                    <mat-select formControlName="phoneType" (selectionChange)="onPhoneTypeChange()">
                        <mat-option *ngFor="let type of phoneTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="form-owner-custom-phone-type" *ngIf="showCustomPhoneTypeField">
                    <mat-label>{{ 'FORM.CONTACT.CUSTOM-PHONE-TYPE' | translate }}</mat-label>
                    <input type="text" matInput formControlName="customPhoneType">
                    <mat-error>{{ 'FORM.REQUIRED' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-owner-email" *ngIf="displayPhoneEmailFields">
                    <mat-label>{{ 'FORM.CONTACT.EMAIL' | translate }} {{ contactInformationRequired ? '' : 'FORM.OPTIONAL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="email"/>
                    <mat-error
                        *ngIf="!form.get('propertyOwnerContact').get('email').value">{{ 'FORM.REQUIRED' | translate }}</mat-error>
                    <mat-error
                        *ngIf="form.get('propertyOwnerContact').get('email').value">{{ 'FORM.INVALID_EMAIL' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="info-form form-contact">
                <h2 class="form-label">
                    <div class="form-label-part">{{ 'FORM.NOTE.LABEL' | translate }}</div>
                </h2>
                <mat-form-field class="form-note">
                    <mat-label>{{ 'FORM.NOTE.NOTE' | translate }}</mat-label>
                    <textarea class="text-area" matInput formControlName="note"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>

    <span class="legalNotice">{{ 'FORM.LEGAL_NOTICE' | translate }}</span>
    <mat-card-actions>
        <div class="action-buttons">
            <button mat-raised-button color="warn" (click)="cancel.emit()">
                {{ 'FORM.CANCEL' | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="onSubmit()"
                [disabled]="!form.valid"
            >
                {{ 'FORM.SUBMIT' | translate }}
            </button>
        </div>
    </mat-card-actions>
</mat-card>
