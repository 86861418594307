<div class="container">
    <mat-card appearance="outlined">
        <mat-card-title><h2>{{ 'STATUS-CARD.TITLE' | translate }}</h2></mat-card-title>
        <mat-card-content>
            <div>
                <strong>{{ 'STATUS-CARD.VENDOR' | translate }}</strong>
                <app-vendor-full-logo
                    class="vendor-logo"
                    alignment="center"
                    [vendor]="getVendorInfo()"
                ></app-vendor-full-logo>
            </div>
            <div>
            <span>
                <strong>{{ 'STATUS-CARD.SERVICE' | translate }}</strong>
                <ng-container *ngIf="serviceInfo?.serviceDisplayName; else defaultServiceDisplayName">
                    {{ serviceInfo.serviceDisplayName }}</ng-container>
                <ng-template #defaultServiceDisplayName>
                    {{ 'VENDOR-SERVICE.' + projectInfo.type | translate }}
                </ng-template>
            </span>
            </div>
            <div>
                <span>{{ projectInfo.street }}, {{ projectInfo.city }} {{ projectInfo.state }}
                    , {{ projectInfo.zip }}</span>
            </div>
            <div>
            <span>
                <strong>{{ 'STATUS-CARD.REQUESTED' | translate }}</strong>
                {{ projectInfo.dateCreated | intlDate }}
            </span>
            </div>
            <div *ngIf="projectInfo.dateUpdated">
            <span>
                <strong>{{ 'STATUS-CARD.UPDATED' | translate }}</strong>
                {{ projectInfo.dateUpdated | intlDate }}
            </span>
            </div>
            <div>
            <span>
                <strong>{{ 'STATUS-CARD.STATUS' | translate }}</strong>
                {{ 'SERVICE-STATUS.' + projectInfo.status | translate }}
            </span>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="button-container">
                <button mat-raised-button
                        (click)="onRequestAgain()"
                        [disabled]="showButtonSpinner"
                >{{ 'REQUEST-AGAIN' | translate }}
                </button>
                <mat-spinner class='spinner' *ngIf="showButtonSpinner" [diameter]="20"></mat-spinner>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
