import { Component, OnInit, ViewChild } from '@angular/core';
import { IProjectInfo } from '../../model/i-project-info';
import { ProjectHistoryTableComponent } from '../../shared/components/project-history-table/project-history-table.component';
import { ProjectService } from '../../shared/services/project.service';
import { VendorService } from '../../shared/services/vendor.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ProjectStatus } from '../../model/project-status';
import { RouteEnum } from '../../shared/enums/routes.enum';
import { BatchProjectInfo } from '../../model/batch-project-info';

@Component({
  selector: 'app-batch-service-request-page',
  templateUrl: './batch-service-request-page.component.html',
  styleUrls: ['./batch-service-request-page.component.scss']
})
export class BatchServiceRequestPageComponent implements OnInit {
  batchProjectInfo: BatchProjectInfo = null;

  shouldShowRequestSteps = true;

  @ViewChild(ProjectHistoryTableComponent)
  historyTable: ProjectHistoryTableComponent;

  vendorServiceStatus: 'loading' | 'loaded' | 'error' = 'loading';

  constructor(
    private projectService: ProjectService,
    private vendorService: VendorService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.projectService.requestBatchInformation().subscribe(res => {
      if (!environment.production) {
        // tslint:disable-next-line:no-console
        console.debug('project info loaded');
        // tslint:disable-next-line:no-console
        console.debug(res);
      }
      this.batchProjectInfo = new BatchProjectInfo(res);
      this.shouldShowRequestSteps = this.batchProjectInfo.projects.some(p => p.status === ProjectStatus.New);

      const vendorServiceIds = this.batchProjectInfo.getVendorServiceIds();

      // vendor infos
      if (!!vendorServiceIds) {
        this.vendorService.requestVendorList(Array.from(vendorServiceIds)).subscribe(vendors => {
            if (!environment.production) {
              // tslint:disable-next-line:no-console
              console.debug(`${vendors.length} vendor infos loaded`);
              // tslint:disable-next-line:no-console
              console.debug(vendors);
            }
            this.vendorServiceStatus = 'loaded';
          }, () => {
            this.vendorServiceStatus = 'error';
          }
        );
      } else {
        console.error('project has no associated service ids');
        this.vendorServiceStatus = 'error';
      }
    }, () => {
      this.router.navigate([RouteEnum.PROJECT_ERROR]);
    });
  }

  onRequestComplete(): void {
    this.historyTable.reloadData();
  }
}
